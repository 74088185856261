import { Api } from "@/models/class/api.class";
import { RequestWarehouseBranchCreate, ResponseBranchWarehouse, ResponseListBranchWarehouse } from "@/models/interface-v2/warehouse.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class WarehouseBranchService extends HttpClient {
  constructor() {
    super()

    this.listWarehouseBranch = this.listWarehouseBranch.bind(this)
    this.createWarehouseBranch = this.createWarehouseBranch.bind(this)
  }

  public listWarehouseBranch(params : RequestQueryParamsModel): Promise<ResponseListBranchWarehouse> {
    return this.get<ResponseListBranchWarehouse>(Api.WarehouseBranch, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  public createWarehouseBranch(payload: RequestWarehouseBranchCreate): Promise<ResponseBranchWarehouse> {
    return this.post<ResponseBranchWarehouse, RequestWarehouseBranchCreate>(Api.WarehouseBranch , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
}

export const warehouseBranchService = new WarehouseBranchService()