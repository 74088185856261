





































import { debounceProcess } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import Vue from "vue";
import { warehouseBranchService } from "@service/warehouse-branch.service";
import { ResponseListBranchWarehouse, ResponseBranchWarehouse } from "@interface/warehouse.interface";
import { IOption } from "@/models/interface-v2/common.interface";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";

export default Vue.extend({
  name: "CSelectBranch",
  props: {
    value: {
      type: String,
      default: undefined
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.searchBranch = debounceProcess(this.searchBranch, 300);
    return {
      loading: false,
      dtOpt: [] as IOption<ResponseBranchWarehouse>[],
      init: false,
    };
  },
  created() {
    this.searchBranch();
  },
  methods: {
    getListBranch(params: RequestQueryParamsModel): Promise<ResponseListBranchWarehouse> {
      return warehouseBranchService.listWarehouseBranch(params);
    },
    onSelect(val: string): void {
      this.$emit("input", val);
      this.$emit("on-change", val);
    },
    onClick(val: string, meta: ResponseBranchWarehouse): void {
      this.$emit("on-click", { value: val, meta });
    },
    async searchBranch(search = ""): Promise<void> {
      try {
        this.loading = true;
        const params: RequestQueryParamsModel = {
          limit: DEFAULT_PAGE_SIZE,
          page: 0,
          sort: "createdDate:desc"
        };
        if (search) {
          params.search = `name~*${search}*`;
        }
        const res = await this.getListBranch(params);
        this.dtOpt = res.data.map(x => ({ key: x.name, value: x.id, meta: x }));
        if (!this.init && !this.value) {
          this.init = true;
          this.onSelect(this.dtOpt[0].value);
          this.$emit("on-init", { value: this.dtOpt[0].value, meta: this.dtOpt[0].meta });
        }
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading = false;
      }
    },
    onChange(value: string): void {
      this.$emit("change", value);
    },
    clear(): void {
      this.$emit("input", undefined);
      this.$emit("on-clear", { value: "", meta: undefined });
    },
  }
});

